/* Variables overrides */
$primary: #0e76bc;
$secondary: #F7941E;
$tertiary:  #777;
$border-color: #ddd;

$body-color:#444;
$headings-color: $primary;
$link-color: $secondary;
$breadcrumb-text-color: #333;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: #f7f7f7;

$search-home-bg-color: $primary;
$search-overview-bg-color: $border-color;

$mobile-header-background: white;
$mobile-header-color: $primary;

$navbar-dark-color: $primary;
$navbar-dark-active-color: $link-color;
$navbar-dark-hover-color: $link-color;

$bottom-footer-bg-color: darken($primary, 5%);

$border-radius: 4px;


@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700');
$font-family-sans-serif: 'Ubuntu', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Ubuntu', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$btn-font-family: 'Ubuntu', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$headings-font-family: 'Ubuntu', Arial, Helvetica, "Nimbus Sans L", sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

/* Extra overrides */
@include media-breakpoint-up(xl) {
  #header > .header.header-top-strip .main .container .logo img {
    max-height: 50px;
    max-width: 378px;
  }
}
#header > .header nav .nav-link {
  font-size: 1.125rem;
}
.node-type-overview-page #block-helper-search-search-holiday-homes h2 {
  color: $primary;
  font-weight: 700;
}

#header > .header.header-top-strip .main .container .logo img {
  margin-top: -0.75rem;
}

@include media-breakpoint-up(xl) {
  #header > .header nav .nav-link {
    margin-right: 0.5rem;
  }
}
